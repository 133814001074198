import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AreaFilters from 'src/components/areas/area-details/area-filters';
import AreaSearch from 'src/components/areas/area-details/area-search';
import { FIND_AREAS, GET_AREA_COORDINATORS } from 'src/components/areas/areas-queries';
import * as styles from 'src/components/areas/manage-areas.module.less';
import RemoveAreaCoordinatorModal from 'src/components/areas/removeAreaCoordinatorModal';
import Button from 'src/components/button/button';
import CoordinatorsTable from 'src/components/coordinators-table/coordinators-table';
import Icon from 'src/components/icon/icon';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import SuccessNotification from 'src/components/success-notification/success-notification';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { Body } from 'src/components/typography/typography';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { FindAreas, FindAreasVariables } from 'src/graphql-types/FindAreas';
import {
  GetAreaCoordinators_resourcesByEntityId_results as CoordinatorListItem,
  GetAreaCoordinators,
  GetAreaCoordinatorsVariables,
} from 'src/graphql-types/GetAreaCoordinators';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { OrganisationTypeEnum } from 'src/graphql-types/globalUstaTypes';
import { useDebounce } from 'src/hooks/use-debounce';
import getSortOptions from 'src/utils/helper/sortOptions';
import ROUTES from 'src/utils/routes';
import { NavigationState } from 'src/utils/typedefs/navigation';

import InviteCoordinatorModal from './invite-coordinator-modal';

interface GetAreaCoordinatorsVariablesWithPagination extends GetAreaCoordinatorsVariables {
  offset: number;
  limit: number;
  sorts?: PropertySortInput[];
}

const CoordinatorsList: React.FC = () => {
  const [search, setSearch] = useState('');
  const [inviteStatus, setInviteStatus] = useState('');
  const [areaFilter, setAreaFilter] = useState('');
  const searchFilter = useDebounce(search, 500);
  const { t } = useTranslation();
  const orgId = useOrgId();
  const [showInviteCoordinatorModal, setShowInviteCoordinatorModal] = useState(false);
  const [selectedCoordinator, setSelectedCoordinator] = useState<CoordinatorListItem | undefined>();
  const [showModal, setShowModal] = useState(false);

  const filters = useMemo(() => {
    return {
      entityIds: [areaFilter ? areaFilter : orgId],
      entitySubTypes: ['AREA'],
      includeChildren: true,
      ...(searchFilter && { fullName: searchFilter }),
      ...(inviteStatus && { roleStates: [inviteStatus] }),
    };
  }, [areaFilter, inviteStatus, orgId, searchFilter]);

  const handleRemoveClick = (coordinator: CoordinatorListItem) => {
    setSelectedCoordinator(coordinator);
    setShowModal(true);
  };

  const {
    data: areaCoordinators,
    controlProps,
    loading,
    refetch,
  } = useControlledQuery<GetAreaCoordinators, GetAreaCoordinatorsVariablesWithPagination>(GET_AREA_COORDINATORS, {
    client: meshGatewayClient,
    getTotalItems: (area: GetAreaCoordinators) => area.resourcesByEntityId?.total ?? 0,
    notifyOnNetworkStatusChange: true,
    transformVariables: ({ limit, offset, sorts }: GetAreaCoordinatorsVariablesWithPagination) => {
      const sort = getSortOptions(sorts);

      return {
        filter: { ...filters },
        pageArgs: { limit, skip: offset },
        ...(sort && { sort }),
      };
    },
  });

  const { data } = useQuery<FindAreas, FindAreasVariables>(FIND_AREAS, {
    client: meshGatewayClient,
    variables: {
      filter: {
        organisationType: { eq: OrganisationTypeEnum.AREA },
        parentOrganisationId: orgId,
      },
    },
  });

  const handleSuccess = () => {
    const state: NavigationState = {
      popup: {
        message: t('invite area coordinator success'),
      },
    };
    navigate('/areas/manage-coordinators', { state });
  };

  return (
    <PageMaxWidth>
      <Grid container justifyContent="space-between" alignItems="center">
        <div>
          <PageHeader title={t('coordinators')} noPadding />
          <Body size="md">{t('manage all coordinators')}</Body>
        </div>
        <Button
          spacing={{ margins: { sm: 'bottom' } }}
          onClick={() => setShowInviteCoordinatorModal(true)}
          testId="add-coordinator-button"
        >
          <Icon name="sm-add" className={styles.addIcon} />
          {t('invite coordinator')}
        </Button>
      </Grid>
      <Panel extendedPadding>
        <div className={styles.filterContainer}>
          <AreaSearch search={search} setSearch={setSearch} placeholder={t('search name')} />
          <AreaFilters
            inviteStatus={inviteStatus}
            setInviteStatus={setInviteStatus}
            areaFilter={areaFilter}
            setAreaFilter={setAreaFilter}
            areaOptions={data?.findOrganisations?.results}
          />
        </div>
        <CoordinatorsTable
          type={'area'}
          data={areaCoordinators?.resourcesByEntityId?.results}
          loading={loading}
          controls={controlProps}
          onRemove={handleRemoveClick}
          filters={filters}
        />
        <InviteCoordinatorModal
          show={showInviteCoordinatorModal}
          setShowInviteCoordinatorModal={setShowInviteCoordinatorModal}
          areas={data?.findOrganisations?.results}
          onSuccess={handleSuccess}
        />
      </Panel>

      <RemoveAreaCoordinatorModal
        organisationId={selectedCoordinator?.entityId ?? ''}
        onSuccess={refetch}
        coordinatorId={selectedCoordinator?.userId ?? ''}
        areaOwnerId={selectedCoordinator?.organisation?.ownerId}
        visible={showModal}
        onVisibleChange={setShowModal}
      />
      <SuccessNotification atUrl={ROUTES.AREAS_COORDINATORS} hideAfterMs={5000} />
    </PageMaxWidth>
  );
};

export default CoordinatorsList;
